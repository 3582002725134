.checkbox-wrapper {
  display: flex;
  align-items: center;

  .checkbox[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    accent-color: var(--primary);
    margin: 0;
    cursor: pointer;
  }

  label {
    margin-left: 6px;
    line-height: 1;
  }
}



