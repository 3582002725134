$transition: all .25s linear;
$slide-padding-horizontal: .75rem;
$slide-padding-vertical: .5rem;

.slides-nav {
  width: $slides-list-width;
  height: 100%;
  background-color: var(--gray-lighter);
  border: 1px solid var(--gray-light);
  overflow-y: auto;

  .add-slide {
    @include sticky($top: 0);
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .75rem;
    width: 100%;
    height: 3.75rem;
    background-color: var(--gray-lighter);
    border-bottom: 1px solid var(--gray-light);

    span {
      color: var(--headeer-bar-color);
    }

    svg {
      color: var(--headeer-bar-color);
      cursor: pointer;
      width: 28px;
      height: 28px;
    }
  }

  .slides-group {
    div{
      min-height: 0px !important;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: .75rem;

      .slide-name {
        display: flex;
        align-items: center;
        overflow: hidden;

        svg {
          margin-right: 10px;
          cursor: grab;
        }

        span {
          @include text-ellipsis;
          padding-left: .75rem;

        }
      }

      svg {
        color: var(--headeer-bar-color);
        cursor: pointer;
      }
    }

    .slide {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: $slide-padding-vertical $slide-padding-horizontal;
      gap: .5rem;
      transition: $transition;

      .position {
        display: flex;
        align-items: center;
        width: 28px;
        height: 100%;

        .drag-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          transition: $transition;

          svg {
            z-index: 1;
            transform: translateX(-6px);
            color: var(--gray-mid);

            path {
              fill: var(--gray-mid);
              transition: $transition;
            }
          }

          &::before {
            @include absolute;
            z-index: 1;
            content: '';
            width: 15px;
            height: 1.25rem;
            background-color: var(--gray-lighter);
            transform: translateY(-25%);
            transition: $transition;
          }

          &::after {
            @include absolute($top: $slide-padding-vertical, $bottom: $slide-padding-vertical, $left: $slide-padding-horizontal);
            content: '';
            width: 1.5px;
            background-color: var(--gray-mid);
            transition: $transition;
          }

          &.hidden {
            opacity: 0;
          }
        }

        p {
          color: var(--gray-mid);
          transition: $transition;
        }
      }

      .dropdown-wrapper {
        transition: $transition;

        &.hidden {
          opacity: 0;
        }
      }

      .slide-thumbnail {
        width: 163px;
        height: 50%;
        border-radius: 2px;

        &.hidden {
          .name {
            opacity: .6;
          }

          img {
            opacity: .4;
          }
        }

        .img {
          @include aspect-ratio(16, 9);

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: $transition;
          }
        }

        .name {
          @include text-ellipsis;
          text-align: left;
          line-height: 1;
          transition: $transition;
        }
      }

      div {
        svg {
          color: var(--headeer-bar-color);
          cursor: pointer;
        }
      }

      &.selected {
        background-color: var(--primary-light);

        .position {
          .drag-icon {
            &::before {
              background-color: var(--primary-light);
            }

            &::after {
              background-color: var(--headeer-bar-color);
            }

            svg path {
              fill: var(--headeer-bar-color);
            }
          }

          p {
            color: var(--headeer-bar-color);
          }
        }
      }
    }
  }

  .hidden-space {
    height: 100px;
  }
}
