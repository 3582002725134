// ====== Breakpoints
// ======================================

$breakpoint-desktop-lg: 1440px;
$breakpoint-desktop-md: 1280px;
$breakpoint-tablet-lg: 1024px;
$breakpoint-tablet-md: 768px;
$breakpoint-mobile: 600px;

// ====== Sidebar
// ======================================

$sidebar-width: 225px;
$sidebar-mobile-width: 257px;

// ====== Others
// ======================================

$max-screen-width: 1280px;

// ====== Shadows
// ======================================

$shadow-mid: 0 2px 8px #0000001a;

// ====== Colors
// ======================================

$white: #fff;
$black: #000;

$colors: (
  brand-1: (
    primary: #0300ad,
    secondary: #ee6500,
    alternative-1: #da689f,
    alternative-2: #3d4dda,
    alternative-3: #f9c438,
    backdrop: rgba($black, .4)
  ),
  brand-2: (
    primary: #5900ca,
    secondary: #f8b200,
    alternative-1: #db83f1,
    alternative-2: #62b79c,
    alternative-3: #73cdcd,
    backdrop: rgba($black, .4)
  ),
  brand-3: (
    primary: #0c8599,
    secondary: #0b1450,
    alternative-1: #c96645,
    alternative-2: #dab160,
    alternative-3: #49a473,
    backdrop: rgba($black, .4)
  )
);

$state-success: #63c38a;
$state-warning: #ebc745;
$state-error: #ef3737;

$gray-scale: (
  lighter: #f7f7fa,
  light: #e1e1e7,
  mid: #cccdd8,
  dark: #a2a6be,
  darker: #5a5f7a,
  darkest: #2a364c
);
