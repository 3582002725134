.roles {
  width: 100%;
  margin-top: .5rem;

  .container {
    width: 100%;
    overflow-y: auto;
    height: 220px;
    border: 1px solid var(--gray-light);
    padding: 1.25rem;
    background-color: var(--white);
    border-radius: 4px;

    > li {
      list-style: disc;
      margin-left: 1rem;
      
      > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}