.video-player-container {
  video {
    &.video {
      width: 100%;
      height: auto;
      border: 3px solid var(--warning);
      border-radius: .5rem;
    }
  }

  .video-player-controls-wrapper {
    width: 100%;
    margin: .5rem 0;
  
    .video-player-controls {
      margin-top: .5rem;
      display: flex;
      gap: .5rem;

      button {
        &.video-player-control {
          color: var(--primary);
        }
      }
    }
  }

}
