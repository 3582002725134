.modal-preview-candidate {
  .divider {
    width: 100%;
    border: .5px solid var(--gray-light);
    background-color: var(--grey-light);
    margin: .75rem 0;
  }

  .content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    .history-content {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > div {
        flex: 0 0 45%;
      }
    }

    .education-content {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > div {
        flex: 0 0 45%;
      }
    }

    .options {
      margin: 1rem 0;
      white-space: break-spaces;

      small {
        color: var(--gray-dark);
        white-space: pre;
      }

      .work {
        display: flex;
        justify-content: space-between;
        margin: .5rem 0;
      }
    }

  }
}
