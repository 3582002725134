.theme-card-partner {
  background-color: var(--white);
  border: var(--gray-light) 1.5px solid;
  box-shadow: $shadow-mid;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1.5px solid var(--primary);
  }

  &.selected {
    border: 1.5px solid var(--primary-strong);
  }

  .preview-image {
    @include aspect-ratio(16,9);
    width: 100%;

    > img {
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    padding: .75rem;
  }
}
