.react-tabs {
  &__tab-list {
    margin: 0 0 1rem;
  }

  &__tab {
    display: inline-block;
    border-bottom: none;
    position: relative;
    list-style: none;
    font-weight: 600;
    padding: 0.5rem;
    margin: 0rem 1rem;
    cursor: pointer;
    color: var(--gray-dark);

    &--selected {
      border-bottom: 2px solid;
      border-color: var(--headeer-bar-color);
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}