.background {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .top {
    @include absolute($top: calc(var(--vh) * -25), $left: 0);
    justify-content: flex-end;
    animation: anim-cover-top 60s linear alternate infinite;

    > :first-child path {
      opacity: .05;
      fill: var(--alternative-1-light);
    }

    > :last-child path {
      opacity: .1;
      fill: var(--alternative-1);
    }
  }

  .bottom {
    @include absolute($bottom: calc(var(--vh) * -25), $left: 0);
    justify-content: flex-start;
    animation: anim-cover-bottom 60s linear alternate infinite;

    > :first-child path {
      opacity: .075;
      fill: var(--primary-strong);
    }

    > :last-child path {
      opacity: .05;
      fill: var(--primary-strong);
    }
  }

  .top,
  .bottom {
    display: flex;
    width: 100vw;
    height: calc(var(--vh) * 60);

    svg {
      height: 100%;
    }
  }
}

@include keyframes(anim-cover-top) {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@include keyframes(anim-cover-bottom) {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
