.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: var(--alternative-3);
  opacity: .9;

  @include tablet-lg {
    width: 5rem;
    height: 5rem;
  }

  svg {
    width: 50%;
    height: 50%;
    stroke: var(--white);
    stroke-width: 1px;
    transform: translateX(6.66%);
  }
}
