.deck-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;

  .dot {
    width: 10px;
    height: 10px;
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;

    &.draft {
      border-color: var(--primary-light);
    }

    &.signed {
      border-color: var(--primary-strong);
    }

    &.sent-to-approved {
      border-color: var(--secondary);
    }
  }
}
