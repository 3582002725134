.company-card {
  width: 100%;

  .card {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--white);
    border-radius: .25rem;
    border: var(--gray-light) 1px solid;
    box-shadow: $shadow-mid;
    overflow: hidden;
    cursor: pointer;
    padding: .5rem;
    transition: border-color .25s linear;

    &:hover {
      border-color: var(--headeer-bar-color);
    }

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      overflow: hidden;
      @include aspect-ratio(193, 70);

      img {
        height: 100%;
        object-fit: fill;
        pointer-events: none;
        // padding: 0px 4rem 0.25rem;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: .5px solid var(--gray-mid);
      }

      .name {
        // @include text-ellipsis;
        width: 100%;
        padding: 0 .5rem .25rem;
        text-align: center;
      }
    }

    .body {
      padding: 0 1.25rem;
      pointer-events: none;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      gap: .5rem;
      align-items: center;
      padding-top: .5rem;
      pointer-events: none;

      .ellipsis {
        flex: 1;
        @include text-ellipsis;
      }

      .learn-more {
        cursor: pointer;
        color: var(--headeer-bar-color);
      }

      @include tablet-lg {
        flex-direction: column;
        padding: .5rem;
      }
    }
  }
}
