.layout-signature {

  .flex-wrapper {
    display: flex;
    max-width: $max-screen-width;
    width: 100%;
    height: 100%;

    @include tablet-lg {
      flex-direction: column;
      justify-content: space-around;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      overflow: hidden;

      @include tablet-lg {
        padding: 0;
      }

      .wrapper {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        overflow: hidden;

        @include tablet-lg {
          max-width: 100%;

          :nth-child(2) {
            @include multiLineEllipsis(4);
          }

          :nth-child(3) {
            margin-top: 1rem;
          }
        }

        h3 {
          color: var(--title-text-color);

          @include mobile {
            padding: 1rem 0;
          }
        }

        p {
          margin-top: 0.50rem;
        }
        .title-name {
          text-transform: capitalize;
            color: var(--primary);
        }
      }

      .signatures {
        margin-top: .5rem;

        .content {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: .5rem;

          @include tablet-lg {
            align-content: flex-start;
          }

          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 1rem;

            > img {
              width: 100px;
              height: 48px;
            }

            > span {
              margin-top: 1.25rem;
              font-size: 14px;
              color: var(--gray-dark);
              cursor: pointer;
            }
          }
        }
      }
    }

    .signature-video {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

			@include tablet-lg {
        width: 100%;
        max-height: calc(var(--vh) * 50);
      }

      .image-container {
				height: 100%;
				width: 100%;
        overflow: hidden;
        border-radius: .5rem;
        @include aspect-ratio(123, 149);

        img {
          width: 100%;
          height: auto;
          margin-top: auto;
          margin-bottom: auto;
          object-fit: contain;
          border-radius: .5rem;
        }
      }
        
      
    }
  }
}
