$hover-deg: 40;

.logo {
  width: 2rem;
  fill: none;

  > path {
    stroke-width: 3.66355;
    stroke: var(--toolbar-button);
    transform-origin: center;
    transition: all .35s ease-in-out;
  }

  &:hover {
    > path {
      &:nth-child(1) {
        transform: rotateY(#{$hover-deg}deg);
      }
      &:nth-child(2) {
        transform: rotateY(-#{$hover-deg}deg);
      }
      &:nth-child(3) {
        transform: rotateX(-#{$hover-deg}deg);
      }
      &:nth-child(4) {
        transform: rotateX(#{$hover-deg}deg);
      }
    }
  }
}
