.carousel-controls {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0 0 .5rem .5rem;
  height: 6.5rem;

  @include tablet-lg {
    border-radius: 0;
    padding: 1rem 0;
  }

  .bullets-pagination {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: var(--secondary-button-color);

    .swiper-pagination-bullet {
      width: .75rem;
      height: .75rem;
      margin: 0 .5rem 0 .5rem;
      border: 1.5px solid var(--alternative-2);
      background-color: transparent;
      opacity: 1;
    }

    .swiper-pagination-bullets-dynamic {
      transform: none;
    }

    .swiper-pagination-bullet-active {
      color: var(--white);
      background-color: var(--alternative-2);
    }
  }

  .fraction-pagination {
    display: none;
    align-items: center;
    justify-content: center;
    color: var(--secondary-button-color);

    > span {
      display: flex;
      align-items: center;
    }
  }

  .show-pagination {
    flex: 1;
    display: flex;
    text-align: center;
    color: var(--secondary-button-color);
  }

  &.one-page {
    > button {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.contained {
  background-color: var(--gray-lighter);
  display: flex;
  align-items: center;
  border-top: .75px solid var(--gray-light);
}

.small {
  height: 3rem;
}

.medium {
  height: 4.5rem;
}

.large {
  height: 6.5rem;

  @include tablet-lg {
    height: 4.5rem;
  }
}

.sides-spaces {
  @include tablet-lg {
    padding: 1rem;
  }
}
