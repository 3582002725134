.branding-theme-container {
	width: 100%;

	.message {
		text-align: center;
		padding: .5rem .5rem;
		color: var(--gray-mid)
	}

	.save-button{
		margin-bottom: 20px;
		align-items: center;
		display: flex;
		flex-direction: column;
	}

	.branding-theme-form {
		display: grid;
		width: 100%;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 1rem;

		> :last-child {
			grid-column: span 2;
		}

		[type='color'] {
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			padding: 0;
			width: 15px;
			height: 15px;
			border: none;
		}
		
		[type='color']::-webkit-color-swatch-wrapper {
			padding: 0;
		}
		
		[type='color']::-webkit-color-swatch {
			border: none;
		}
		.text1 {
			position: absolute;
			transform: translateY(-50%);
			padding: 0 0.5rem;
			color: var(--gray-dark);
			z-index: 0;
			top: 12px;
			left: 19px;
			background-color: white;
		}

		.input-container{
			margin-top: 6px;
    	position: relative;
			margin-bottom: 6%;
		}

		.dropzone {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 1rem;
			height: 75%;
			background-color: var(--gray-lighter);
			border: 3px var(--gray-dark) dashed;
			border-radius: .5rem;
			width: 30%;
			//margin-left: 40px;
			margin-left: 48px;
			//width: 42%;
	
			.text-center {
				text-align: center;
				margin: 1rem;
				
				.grey-font{
					color: var(--gray-dark);
				}
			}
			
			b {
				font-weight: bold;
			}
			
		}

		.label-name{
			position: relative;
			top:-42%
		}
		.text-name
		{
			position: inherit;
			margin-left: -50px;
		}
		
		.icon
		{
			display: inline-flex;
			margin-left: -1%;
			position: relative;
			top: -76%;
			
		}
		.color-picker {
			padding: 11px 15px;
			border-radius: 5px;
			border: 1px solid #ccc;
			background-color: #fff;
			height: 42px;
			margin-top: 14px;

			.m-left{
				margin-left: 2%;
			}
			
		}

		.text-area {
			padding: 11px 15px;
			border-radius: 5px;
			border: 1px solid #ccc;
			background-color: #fff;
			height: 75px;
			margin-top: 14px;

			.m-left{
				margin-left: 2%;
			}
			
		}
		[type='checkbox'] {
			// height: 40px;
			// width: 30px;
			// margin-top: 12px;
			// margin-left: 20%;
			height: 35px;
    		width: 17px;
   			margin-top: 12px;
    		margin-left: 10%;
		}
		[type='text'] {
			width: 100%;
		}

		.checkbox-label {
			position: relative;
    	bottom: 13px;
			margin-left: 5%;
		}

		.reach-text {
			.ql-container {
				height: 20%;
			}
		}
	}

	.branding-theme-form2 {
		display: flex;
		width: 100%;
		flex-direction: column;
		column-gap: 1rem;

		> :last-child {
			grid-column: span 2;
		}

		[type='color'] {
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			padding: 0;
			width: 15px;
			height: 15px;
			border: none;
		}
		
		[type='color']::-webkit-color-swatch-wrapper {
			padding: 0;
		}
		
		[type='color']::-webkit-color-swatch {
			border: none;
		}
		.text1 {
			position: absolute;
			transform: translateY(-50%);
			padding: 0 0.5rem;
			color: var(--gray-dark);
			z-index: 999;
			top: 12px;
			left: 19px;
			background-color: white;
		}

		.input-container{
			margin-top: 6px;
    	position: relative;
			margin-bottom: 3%;
		}

		.dropzone {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 1rem;
			height: 75%;
			background-color: var(--gray-lighter);
			border: 3px var(--gray-dark) dashed;
			border-radius: .5rem;
			width: 30%;
			margin-left: 40px;
			//width: 42%;
	
			.text-center {
				text-align: center;
				margin: 1rem;
				
				.grey-font{
					color: var(--gray-dark);
				}
			}
			
			b {
				font-weight: bold;
			}
			
		}

		// .label-name {
		// 	position: relative;
		// 	top:0%;
		// }
		// img {
		// 	max-width: 100px;
		// 	max-height: 50px;
		// 	margin-top: 9px;
		// }
		// .text-name {
		// 	position: inherit;
		// 	margin-left: -50px;
		// }
		
		// .icon {
		// 	display: inline-flex;
		// 	// margin-left: -25px;
		// 	position: relative;
		// 	top: -42%;
		// }
		.color-picker {
			padding: 11px 15px;
			border-radius: 5px;
			border: 1px solid #ccc;
			background-color: #fff;
			height: 42px;
			margin-top: 14px;

			.m-left{
				margin-left: 2%;
			}
			
		}

		.text-area {
			padding: 11px 15px;
			border-radius: 5px;
			border: 1px solid #ccc;
			background-color: #fff;
			height: 75px;
			margin-top: 14px;

			.m-left{
				margin-left: 2%;
			}
			
		}
		[type='checkbox'] {
			height: 40px;
			width: 30px;
			margin-top: 12px;
			margin-left: 20%;
		}
		[type='text'] {
			width: 100%;
		}

		.checkbox-label {
			position: relative;
    	bottom: 13px;
			margin-left: 5%;
		}

		.reach-text {
			.ql-container {
				height: 20%;
			}
		}
	}
}
  