.grid-images {
  display: grid;
  grid-template-columns: repeat(4, 200px);
  overflow: hidden;
  gap: 1rem;
  height: calc(var(--vh) * 50);
  overflow-y: auto;
  border: 2px solid var(--alternative-3);
  border-radius: 10px;
  padding: 1rem;
}

.gallery-button {
  display: flex;
  justify-content: space-between;
}