.comments-menu {
  width: 450px;
  padding: 0;
  border-radius: .75rem;
  background-color: var(--gray-lighter);

  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;
    background: var(--headeer-bar-color) 50% 50% / cover;
    color: var(--white);
    border-radius: .5rem .5rem 0 0;

    .button.icon-only {
      color: var(--white);
    }
  }

  .comment-body {
    padding-top: 0;
    height: 100%;
    max-height: calc(var(--vh) * 60);
    overflow-y: auto;
  }

  .comment-footer {
    display: flex;
    justify-content: flex-end;
    padding: .5rem;

    .new-comment {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-end;
      background-color: var(--white);
      border: var(--gray-light) 1px solid;

      textarea {
        width: 100%;
        height: 70px;
        padding: .50rem;
        resize: none;
      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        padding: .5rem;
      }
    }
  }

  .comment-item {
    transition: all .25s ease;
    background: var(--white);
    padding: .75rem;
    margin: .5rem;
    border: var(--gray-light) 1px solid;
    border-radius: .25rem;

    .comment-item-header {
      display: flex;
      align-items: center;
      gap: .5rem;

      small {
        color: var(--gray-mid);
      }
    }

    .comment-item-body {
      padding-top: 0;
    }

    > p {
      @include text-ellipsis;
      text-align: left;
    }

    &:hover {
      background-color: var(--white);
    }
  }
}
