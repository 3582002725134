.share-deck-container {
  .share-deck-header {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  .share-deck-left {
    flex: 1;
  }

  .share-deck-right {
    .select-dropdown {
      width: 7rem;
    }
  }

  .share-deck-body {
    .share-deck-user {
      > * + * {
        margin-block-start: 1rem;
      }

      .share-deck-user-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .details {
          p:nth-child(2) {
            color: var(--gray-dark);
          }
        }

        .actions {
          .shared-user-owner {
            color: var(--gray-dark);
            width: 6.25rem;
            text-align: center;
            height: 2.1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: not-allowed;
          }
        }
      }
      
    }
  }
}
