.file-button {
  cursor: pointer;
  gap: 0;

  .file-button-text {
    display: flex;
    align-items: center;
    gap: .75rem;
    font-weight: 600;
  }

  input {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
}
