.number-input-group {
  display: flex;
  align-items: center;
  max-width: 5rem;
  width: 100%;
  background-color: var(--white);
  border-radius: .25rem;

  input {
    flex: 1;
    display: block;
    width: 100%;
    text-align: center;
    -webkit-appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .btn-input {
    display: block;
    padding: .5rem;
    opacity: 0;

    svg {
      color: var(--gray-dark);
    }

    &:hover {
      svg {
        color: var(--black);
      }
    }
  }

  &:hover {
    .btn-input {
      opacity: 1;
    }
  }
}
