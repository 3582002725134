.input-layout-picker {

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    padding: 1rem;

    > div {
      @include aspect-ratio(16, 9);
      text-align: center;

      img {
        width: 100%;
        border: 1.5px solid transparent;
        border-radius: 3px;
        transition: all .25s ease !important;

        &:hover {
          cursor: pointer;
          border-color: var(--headeer-bar-color);
        }
      }

      &.selected img {
        border: 1.5px solid var(--headeer-bar-color);
      }
      &.selected_img img {
        border: 1.5px solid var(--headeer-bar-color);
      }

      &.disabled {
        cursor: default;
        pointer-events: none;

        img {
          opacity: .4 !important;
        }

        p {
          opacity: .6;
        }
      }
    }
  }
}
