.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .card {
    width: 100%;
    box-shadow: $shadow-mid;
    border: var(--gray-light) 1px solid;
    border-radius: .25rem;
    background-color: var(--white);
    overflow: hidden;
    transition: border-color .25s linear;
    text-align: center;

    .header {
      position: relative;
      width: 100%;
      height: 200px;
      background-color: var(--gray-lighter);

      .linkedin-logo {
        @include absolute($top: .75rem, $right: .75rem);
        z-index: 1;
        display: flex;
        padding: 2.5px;
        border-radius: .25rem;
        background-color: var(--primary-strong);

        svg {
          width: 24px;
          height: 24px;

          * {
            fill: var(--white);
            stroke-width: 0;
          }
        }
      }

      > img {
        height: 100%;
        object-fit: revert;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 1.25rem 0;
      //height: 210px;

      @include tablet-lg {
        padding: 1rem;
      }

      h6 {
        margin: .25rem 0;
        color: var(--title-text-color);

        .linkedin-logo {

          border-radius: .25rem;
          top: 10px;
          margin-left: 10px;
  
          svg {
            width: 20px;
            height: 20px;
            padding: 3px;
            background-color: var(--primary-strong);
            border-radius: .25rem;
  
            * {
              fill: var(--white);
              stroke-width: 0;
            }
          }
        }
      }

      span {
        margin: .25rem 0;
      }

      .user {
        display: flex;
        margin-top: auto;
      }

      .company-and-title {
        color: var(--gray-dark);
        text-align: left;
      }

      .content {
        line-height: 1.34;
        @include multiLineEllipsis(3);
      }

      .divider {
        width: 100%;
        height: .5px;
        margin: .5rem 0;
        background: var(--gray-dark);
        padding: 0;
        border: none;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      @include tablet-lg {
        padding: 1rem;
      }

      .thumbs {
        display: flex;
        justify-content: space-around;
        align-items: center;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.75rem;
          height: 2.75rem;
          border-radius: .25rem;
          transition: all .25s linear;

          &[disabled] {
            cursor: default;
          }

          svg {
            height: 18px;
          }

          &.like {
            margin-left: .5rem;

            svg {
              color: $state-success;
            }

            &.marked {
              border-color: $state-success;
              background-color: rgba($state-success, .2);
            }

            &:not(.marked):hover {
              background-color: rgba($state-success, .05);
            }
          }

          &.dislike {
            svg {
              color: $state-error;
            }

            &.marked {
              background-color: rgba($state-error, .2);
            }

            &:not(.marked):hover {
              background-color: rgba($state-error, .05);
            }
          }
        }
      }

      .learnmore {
        color: var(--title-text-color);

        .button {
          height: 0;
          padding: 0;
          font-weight: 500;
          font-size: 16px;
          text-decoration: none;
          border: none;
        }
      }
    }
  }

  .card:hover {
    border: var(--headeer-bar-color) 1px solid;
  }
}
