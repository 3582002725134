.search-info-container {
  width: 100%;

  #react-select-2-listbox{
    z-index: 0;
  }
  .autocomplete_wrapper .label{
    top: 0px;
  }
  .message {
    text-align: center;
    padding: .5rem .5rem;
    color: var(--gray-mid)
  }

  .search-info-form {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;

    > :last-child {
      grid-column: span 2;
    }
  }
}
