.page-decks {
  display: flex;
  flex-direction: column;
  padding: 1.75rem 1.5rem;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h6 {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      svg {
        transform: translateY(2px);
        margin-left: .5rem;
        color: var(--headeer-bar-color);
      }
    }
  }

  section {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    .tabs {

      > button {
        height: 2.5rem;
        margin-right: 1.5rem;
        padding: 0 1rem;
        border-bottom: 2px solid transparent;
        color: var(--gray-darker);
        transition: all .25s linear;

        &.selected {
          color: var(--headeer-bar-color);
          border-bottom-color: var(--headeer-bar-color);
        }
      }
    }

    .table-wrapper {
      flex: 1;
      overflow: hidden;
    }
  }
}
