.sub-category-card {
  width: 98%;
  border: 1px solid #ECEDF2;
  margin: 20px 0 10px 5px;

  .company-name-new {
    background-color: #ECEDF2;
    height: 40px;
    padding-left: 10px;
    padding-top: 8px;
    font-size: 20px;
    font-weight: 600;
  }
  .row {
    // columns: 2 auto;
    display: flex;
    margin: 15px 0;
    .company-left {
      padding-left: 10px;
      width: 40%;
      font-weight: 600;
      font-size: 16px;
    }
    .company-right {
      padding-left: 10px;
      width: 60%;
    }
  }

  .card {
    position: relative;
    flex-direction: column;
    background-color: var(--white);
    border-radius: .25rem;
    border: var(--gray-light) 1px solid;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    padding: 2.75rem .75rem .75rem;
    height: 130px;
    transition: border-color .25s linear;

    &:hover {
      border-color: var(--headeer-bar-color);
    }

    @include desktop {
      height: 170px;
    }

    @include md-desktop {
      height: 200px;
    }

    &.partner {
      height: 170px;
    }

    .card:hover {
      box-shadow: $shadow-mid;
      border: var(--primary) 1px solid;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      .company {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: .5rem;
        border-bottom: 1px solid var(--gray-light);

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          display: none;
        }

        @include desktop {
          &:nth-child(2) {
            display: flex;
            border-bottom: 0;
          }
        }

        @include md-desktop {
          &:nth-child(2) {
            display: flex;
            border-bottom: 1px solid var(--gray-light);
          }

          &:last-child {
            display: flex;
            border-bottom: 0;
          }
        }

        .company-name {
          flex: 80%;
          padding-right: 1rem;
          @include text-ellipsis;
        }

        .company-roles {
          flex: 20%;
          text-align: end;
          color: var(--headeer-bar-color);
          white-space: nowrap;
        }
      }
    }

    .badge-container {
      @include absolute($top: 0, $left: 0);
      padding: .75rem;
    }
  }

  .show-more {
    @include absolute($bottom: 0, $left: 0);
    display: flex;
    align-items: center;
    padding: .75rem;
    color: var(--headeer-bar-color);

    svg {
      width: 18px;
      height: 18px;
    }

    span {
      margin-right: 5px;
    }
  }
}
