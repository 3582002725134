.import-workbench-main{
    overflow: scroll; 
    width: 100%;

    .workbench-div{
        margin: 13px 0 0 30px;

        .workbenchSelect{
            font-weight: 600;
            display: flex;
        }

        .workbenchLabel{
            color: #4240C1;
            font-weight: bold;
        }
    }
}