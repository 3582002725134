.tooltip {
 
  &.disabled > [class$="BaseToolTop"] {
    opacity: 0 !important;
  }

  > [class$="BaseToolTop"] {
    z-index: 101;
  }

  > [class^="css-"] > div {
    background-color: var(--primary-strong) !important;
    padding: .5rem .75rem !important;
    white-space: nowrap;
    color: var(--white) !important;
    font-weight: 600;
    font-size: 13px;
    border: none;

    > [class^="css-"] {
      display: none;
    }
  }
}

.szh-menu-container + [class$="BaseToolTop"] {
  opacity: 0 !important;
}
