.toolbar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: $toolbar-height;
  padding: 0 1.5rem;
  border-bottom: 1px solid var(--headeer-bar-color);

  > * {
    z-index: 2;
  }

  .middle-slot {
    display: flex;
    flex: 1;
  }

  .end-slot {
    display: flex;
    gap: .5rem;
  }

  > .background {
    @include absolute($top: 0, $left: 0);
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    background-color: var(--headeer-bar-color);
  }

  .logo {
    float: left;
  }
  img {
    max-height: 45px;
    max-width: 90px;
    vertical-align: middle;
  }
}
