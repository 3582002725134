.layout-signature {

  .flex-wrapper {
    display: flex;
    max-width: $max-screen-width;
    width: 100%;
    height: 100%;

    @include tablet-lg {
      flex-direction: column;
      justify-content: space-around;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      overflow: hidden;

      @include tablet-lg {
        padding: 0;
      }

      .wrapper {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        overflow: hidden;

        @include tablet-lg {
          max-width: 100%;

          :nth-child(2) {
            @include multiLineEllipsis(4);
          }

          :nth-child(3) {
            margin-top: 1rem;
          }
        }

        h3, .learn-more {
          color: var(--title-text-color);
          padding: 1rem 0;

          @include mobile {
            padding: 1rem 0;
          }
        }

        .learn-more{
          cursor: pointer;
        }

        p {
          margin-top: 0.50rem;
        }
        .title-name {
          text-transform: capitalize;
          color: var(--title-text-color);
          > h5 {
            color: var(--title-text-color);
          }
        }
      }

      .signatures {
        margin-top: .5rem;

        .content {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: .5rem;

          @include tablet-lg {
            align-content: flex-start;
          }

          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 1rem;

            > img {
              width: 100px;
              height: 48px;
            }

            > span {
              margin-top: 1.25rem;
              font-size: 14px;
              color: var(--gray-dark);
              cursor: pointer;
            }
          }
        }
      }
    }

    .signature-video {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .video-container {
        display: flex;
        width: 420px;
        @include aspect-ratio(1, 1);
        cursor: pointer;

        @include tablet-lg {
          @include aspect-ratio(16, 9);
        }

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: .5rem;

          @include tablet-lg {
            object-position: 0 30%;
          }
        }

        &::after {
          content: '';
          @include full-absolute;
          background-color: var(--black);
          opacity: .3;
          width: 100%;
          height: 100%;
          border-radius: .5rem;
        }

        .play-video {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
    

        .video-text {
          @include absolute($bottom: 0);
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-left-radius: .5rem;
          border-bottom-right-radius: .5rem;
          width: 100%;
          height: 75px;

          @include tablet-lg {
            height: 50px;
          }

          &::after {
            content: '';
            @include full-absolute;
            background-color: var(--gray-darkest);
            opacity: .3;
            width: 100%;
            height: 100%;
            border-radius: 0 0 .5rem .5rem;
          }

          h6 {
            color: var(--white);
            z-index: 1;
          }
        }
      }
    }
  }
}
