// ====== Breakpoints
// ======================================

$breakpoint-desktop-lg: 1440px;
$breakpoint-desktop-md: 1280px;
$breakpoint-tablet-lg: 1024px;
$breakpoint-tablet-md: 768px;
$breakpoint-mobile: 600px;

// ====== Sidebar
// ======================================

$sidebar-width: 225px;
$sidebar-mobile-width: 257px;

// ====== Others
// ======================================

$max-screen-width: 1280px;
$toolbar-height: 4.25rem;
$slides-list-width: 16.25rem;
$slides-form-width: 21rem;

// ====== Shadows
// ======================================

$shadow-mid: 0 2px 20px #0000001a;

// ====== Colors
// ======================================

$white: #fff;
$black: #000;

$colors: (
  default: (
    primary: #0300ad,
    secondary: #ee6500,
    alternative-1: #da689f,
    alternative-2: #3d4dda,
    alternative-3: #f9c438,
    backdrop: rgba($black, .4)
  )
);

$state-success: #63c38a;
$state-warning: #ebc745;
$state-error: #ef3737;

$gray-scale: (
  lighter: #f7f7fa,
  light: #e1e1e7,
  mid: #cccdd8,
  dark: #a2a6be,
  darker: #5a5f7a,
  darkest: #2a364c
);

$font: 'Satoshi';
$font-black: 'Satoshi-Black';
$font-bold: 'Satoshi-Bold';
$font-medium: 'Satoshi-Medium';
$font-regular: 'Satoshi-Regular';

:root{
  --primary: #{mix(#0300ad, white, 75)};
  --primary-strong: #0300ad;
  --primary-light: #{mix(#0300ad, white, 20)};

  --headeer-bar-color: #{mix(#0300ad, white, 75)};
  --primary-button-color: #0300ad;
  --secondary-button-color: #0300ad;
  // --primary-button-color-light: #{mix(var(--primary-button-color), white, 75)};
  --secondary: #{mix(#ee6500, white, 70)};
  --secondary-strong: #ee6500;
  --secondary-light: #{mix(#ee6500, white, 30)};

  --alternative-1: #da689f;
  --alternative-1-light: #{mix(#da689f, white, 70)};

  --alternative-2: #3d4dda;
  --alternative-2-light: #{mix(#3d4dda, white, 70)};
  --title-text-color: #3d4dda;

  --alternative-3: #f9c438;
  --alternative-3-light: #{mix(#f9c438, white, 70)};

  --body-text-color: #2a364c;
  --backdrop: rgba($black, .4);

  --toolbar-button: #fff;
  
  --font: Satoshi;
}
