.input-candidate-dropdown {
  margin: 0;

  &.like {

    > .input {
      background-color: rgba($state-success, .15);
    }

    .alert {
      background-color: rgba($state-success, .1);
      color: $state-success;
    }
  }

  &.dislike {

    > .input {
      background-color: rgba($state-error, .15);
    }

    .alert {
      background-color: rgba($state-error, .1);
      color: $state-error;
    }
  }

  .alert {
    display: flex;
    align-items: center;
    width: calc(100% + 2.5rem);
    margin-left: -1.25rem;
    padding: .75rem 1.25rem;

    > div {
      flex: 1;
      margin: 0 1.25rem;
    }

    svg {
      height: 21px;
      cursor: pointer;
    }
  }

  .input {
    border: 1px solid var(--gray-light);
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
    height: 50px;
    margin: 0 -1.25rem;

    .actions {
      display: flex;
      gap: .5rem;
      align-items: center;
      color: var(--gray-dark);
      cursor: pointer;

      &:first-child {
        svg {
          path {
            fill: var(--gray-dark);
          }
        }
      }
    }

    input {
      margin: 0 1rem;
    }
  }

  .dropdown {
    background-color: var(--gray-lighter);
    padding: 1rem;
    margin: 0 -1.25rem;

    button {
      margin-top: 1rem;
    }
  }
}
