.image-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1.5px solid var(--gray-dark);
  border-radius: .5rem;
  padding: .5rem;
  height: 240px;
  cursor: pointer;

  &.selected {
    border: 1.5px solid var(--primary);
  }

  .image-thumbnail {
    flex: 1;
    width: 100%;
    max-height: 155px;
    object-fit: contain;
    border-radius: 4px;
  }

  .buttons-wrapper {
    width: 100%;
    padding: .5rem;

    .buttons-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .divider {
        width: 1.5px;
        height: 15px;
        background-color: var(--gray-dark);
        margin: .5rem 0;
      }
    }

    .image-description {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .image-name-label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }
  }
}
