.input-partner {
  margin: 0;

  .input {
    border: 1px solid var(--gray-light);
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
    height: 50px;
    margin: 0 -1.25rem;

    .actions {
      display: flex;
      gap: .5rem;
      align-items: center;
      color: var(--gray-dark);
      cursor: pointer;
      
      &:first-child {
        path {
          fill: var(--gray-dark);
        }
      }

      svg {
        width: 22px;
      }
    }

    input {
      margin: 0 1rem;
    }
  }

  .dropdown {
    background-color: var(--gray-lighter);
    padding: 1rem;
    margin: 0 -1.25rem;
  }

  button {
    margin: 1rem 0;
  }
}
