.video-progressbar-container {
  background-color: var(--gray-mid);
  border-radius: 1rem;
  width: 100%;
  height: .33rem;
  margin: .25rem 0;

  .video-progressbar {
    border-radius: 1rem;
    background-color: var(--primary);
    height: 100%;
    transition: all 200ms ease;
  }
}

.video-player-time {
  font-size: .75rem;
}
