.table-tags {
  display: flex;
  flex-direction: row;

  .tag {
    float: left;
    margin-right: .75rem;
  }

  .view-more {
    width: 1rem;
    cursor: pointer;
  }
}

.all-tags {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  padding: 0 .75rem;
}
