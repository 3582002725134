// ====== Brand vars
// ======================================

@mixin set-themes($selector) {
}

// ====== Main color vars
// ======================================

@mixin set-palette($selector) {
  #{$selector} {
    --white: #{$white};
    --black: #{$black};

    --success: #{$state-success};
    --warning: #{$state-warning};
    --error: #{$state-error};

    @each $key, $color in $gray-scale {
      --gray-#{$key}: #{$color};
    }
  }
}
