.select-dropdown {
  padding: 0;

  .select-dropdown-body {
    width: 100%;

    .select-dropdown-divider {
      margin: 0;
    }

    .select-dropdown-item-wrapper {
      padding: 0;
      background-color: var(--white);
      width: 100%;
      display: block;

      &:hover {
        background-color: var(--gray-lighter);

        > * {
          color: var(--black);
        }
      }

      .select-dropdown-item {
        padding: .5rem 1rem;
        border-radius: .25rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        
        &:not(.selected) {
          padding-left: 3rem;
        }

        &.action-item {
          padding-left: 1rem;
        }
      }
    }
  }
}
