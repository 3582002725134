.autocomplete_wrapper {
  position: relative;

  .label {
    @include absolute($top: 0, $left: 10px);
    z-index: 1;

    .text {
      position: relative;
      transform: translateY(-50%);
      padding: 0 .5rem;
      color: var(--gray-dark);

      &.focused {
        color: var(--gray-darkset);
      }

      &::after {
        @include absolute($top: 48%, $left: 0);
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--white);
        z-index: -1;
      }
    }
  }

  .select-container {
    .autocomplete__control {
      border: 1px solid var(--gray-light);
      border-radius: 4px;
      min-height: 42px;
      box-shadow: none;

      &--menu-is-open {
        border: 1px solid var(--primary);
        box-shadow: none;
      }

      &:focus {
        border: 1px solid var(--primary);
      }

      .autocomplete__value-container {
        .autocomplete__single-value {
          font-size: 14px;

          @include tablet-lg {
            font-size: 12px;
          }
        }

        .autocomplete__input-container {
          margin: 0;
          padding-bottom: 0;
        }

        .autocomplete__multi-value {
          //background-color: var(--primary-light);
          background-color: var(--headeer-bar-color);
          color: var(--white);
          //color: var(--primary);
          width: 90px;
          height: 26px;
          .autocomplete__multi-value__label {
            //color: var(--primary);
            color: var(--white);
          }

          .autocomplete__multi-value__remove {
            padding-top: 5px;
            color: var(--white);
            background-color:  var(--headeer-bar-color);
            &:hover {
             //color: var(--primary);
            color: var(--white);
            background-color:  var(--headeer-bar-color);
              background-color: transparent;
            }
          
          }
        }
      }

      .autocomplete__value-container--has-value {
        padding: 0 1rem;
      }
      .autocomplete__multi-value:nth-of-type(1),
      .autocomplete__multi-value:nth-of-type(2){
          margin-top: 16px;
          margin-bottom: 5px;
       }
    }

    .autocomplete__indicators {
      .autocomplete__indicator-separator {
        display: none;
      }

      .autocomplete__clear-indicator {
        display: none;
      }
    }
  }
}

.autocomplete__menu-portal {
  margin: 0;

  .autocomplete__menu {
    &-list {
      .autocomplete__option {
        padding: 1rem;
        border-top: 1px solid var(--gray-light);
        font-size: 14px;

        @include tablet-lg {
          font-size: 12px;
        }

        &--is-focused {
          background-color: var(--gray-lighter);
        }

        &--is-selected {
          background-color: var(--gray-lighter);
          color: var(--black);
        }
      }
    }
  }
}
