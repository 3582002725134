:root {
  --toastify-color-dark: #{map-get($gray-scale, darkest)};
  --toastify-toast-min-height: 3.25rem;
  --toastify-text-color-dark: #{$white};
}

.Toastify__toast-container {
  border-radius: .5rem;
  width: auto;

  &.Toastify__toast-container--top-center {
    top: calc(1rem + #{$toolbar-height});
  }

  .Toastify__toast {
    padding: .5rem 1rem;

    .Toastify__toast-body {
      justify-content: center;

      > div {
        line-height: 1.75rem;
        font-size: 1rem;
        flex: 0 0 auto;
        font-weight: 700;
      }
    }
  }
}
