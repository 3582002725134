.input-range {
  margin: 10px 50px;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: .5rem;
  background: var(--gray-dark);
  border-radius: 5px;
  background-image: linear-gradient(var(--gray-dark), var(--gray-dark));
  background-size: 100%;
  background-repeat: no-repeat;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--primary);
  cursor: pointer;
  box-shadow: 0 0 2px 0 var(--gray-dark);
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--primary);
  cursor: pointer;
  box-shadow: 0 0 2px 0 var(--gray-dark);
}
