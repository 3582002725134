.layout-media {
  .reverse {
    flex-direction: row-reverse;
  }

  .flex-wrapper {
    @include tablet-lg {
      flex-direction: column;
      justify-content: space-around;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      @include tablet-lg {
        justify-content: flex-start;
      }
    }

    .left {
      width: 55%;

      @include tablet-lg {
        width: 100%;
        flex: 0;
        height: 200px;


      }

      h3 {
        color: var(--title-text-color);

        @include tablet-lg {
          padding: 1rem 0;
        }
      }

      .body {
        margin-top: 1.75rem;

        @include tablet-lg {
          margin-top: .75rem;
        }
      }
    }

    .right {
      width: 45%;

      @include tablet-lg {
        width: 100%;
        max-height: calc(var(--vh) * 50);
      }
      
      .image-container {
        overflow: hidden;
        border-radius: .5rem;
        @include aspect-ratio(123, 149);

        img {
          width: 100%;
          height: auto;
          margin-top: auto;
          margin-bottom: auto;
          object-fit: contain;
          border-radius: .5rem;
        }
      }

      .video {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
  
        @include mobile {
          padding: 2rem 0;
        }
  
        .container {
          position: relative;
          display: flex;
          width: 100%;
          max-width: 420px;
          @include aspect-ratio(1, 1);
  
          @include tablet-lg {
            height: 240px;
          }
  
          .video-preview {
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            object-fit: cover;
  
            @include tablet-lg {
              object-fit: cover;
              object-position: 0 30%;
            }
          }
  
          &::after {
            content: '';
            @include full-absolute;
            background-color: var(--black);
            opacity: .3;
            width: 100%;
            height: 100%;
            border-radius: .5rem;
          }
  
          .play-video {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
          }
  
          .video-text {
            position: absolute;
            bottom: 0%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
            width: 100%;
            height: 75px;
  
            @include tablet-lg {
              height: 50px;
            }
  
            &::after {
              content: '';
              @include full-absolute;
              background-color: var(--gray-darkest);
              opacity: .3;
              width: 100%;
              height: 100%;
              border-radius: 0 0 .5rem .5rem;
            }
  
            h6 {
              color: var(--white);
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
