.layout-signature-document {
  .flex-wrapper {
    display: flex;
    max-width: $max-screen-width;
    width: 100%;
    height: 100%;

    @include tablet-lg {
      flex-direction: column;
    }

    .info {
      display: flex;
      justify-content: center;
      width: 100%;

      @include tablet-lg {
        padding: 0;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-width: 500px;

        @include tablet-lg {
          max-width: 100%;
          max-height: 240px;

          :nth-child(2) {
            height: 110px;
            @include multiLineEllipsis(4);
          }

          :nth-child(3) {
            margin-top: 1rem;
          }
        }

        > h3 {
          color: var(--title-text-color);

          @include mobile {
            padding: 1rem 0;
          }
        }

        > p {
          margin-top: 1.75rem;
        }
      }

      .footer {
        margin: 1rem 0;
        
      }
    }

    .signature-pdf {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @include mobile {
        display: contents;
      }

      .pdf-container {
        position: relative;
        display: flex;
        justify-content: space-around;
        background-color: var(--gray-light);
        border-radius: .25rem;
        padding: 1rem;

        > img {
          transform: scale(1.7);
          @include tablet-lg {
            object-fit: cover;
            object-position: 0 30%;
          }
        }

        .pdf-buttons {
          display: none;
        }

        &:hover {
        
          .pdf-buttons {
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            width: 100%;
            height: 100%;

            h6 {
              color: var(--white);
              z-index: 1;
            }

          }
        }
      }
    }
  }
}
