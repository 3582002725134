.button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  gap: .75rem;
  padding: 0 1.75rem;
  border-radius: .25rem;
  transition: all .25s linear;

  &.primary,
  &.secondary {
    text-transform: uppercase;
  }

  &[disabled] {
    cursor: default !important;
  }

  &.primary {
    background-color: var(--primary-button-color);
    color: var(--white);

    &:hover {
      background-color: var(--primary-button-color);
      opacity: 0.7;
    }

    &:focus,
    &:active {
      background-color: var(--primary-button-color);
    }

    &[disabled] {
      background-color: var(--gray-dark);
      color: var(--white);
    }
  }

  &.secondary {
    border: 1px solid var(--secondary-button-color);
    color: var(--secondary-button-color);

    &:hover {
      border-color: var(--secondary-button-color);
      color: var(--secondary-button-color);
    }

    &.contrast {
      border-color: var(--toolbar-button);
      color: var(--toolbar-button);

      &:hover {
        border-color: var(--toolbar-button);
        color: var(--toolbar-button);
      }
    }

    &[disabled] {
      background-color: var(--white);
      border-color: var(--gray-dark);
      color: var(--gray-dark);
    }

    &.contrast[disabled] {
      background-color: transparent;
    }
  }

  &.alternative {
    color: var(--headeer-bar-color);
    border-color: var(--headeer-bar-color);
    &:focus {
      color: var(--headeer-bar-color);
    }

    &.contrast {
      color: var(--toolbar-button);

      &:focus {
        color: var(--gray-light);
      }
    }

    &[disabled] {
      background-color: transparent;
      border-color: var(--gray-dark);
      color: var(--gray-dark);
      text-decoration: none;
    }

    &.selected {
      background-color: var(--gray-light);
    }
  }

  &.rounded {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    padding: 0;
    border-radius: 50%;
    border: 1px solid var(--toolbar-button);
    color: var(--toolbar-button);
  }

  &.icon-only {
    width: 45px;
    min-width: 45px;
    padding: 0;

    @include tablet-lg {
      width: 38px;
      min-width: 38px;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.small,
  &.tiny {
    min-height: auto;
    height: auto;
    line-height: 1rem;
  }

  &.small {
    gap: .5rem;
    padding: .5rem 1rem;
    font-size: 12px;

    svg {
      height: 14px;
    }
  }

  &.tiny {
    gap: .25rem;
    padding: 6px .5rem;
    font-size: 9.5px;
  }

  svg {
    height: 18px;

    @include tablet-lg {
      height: 16px;
    }
  }

  .badge {
    @include absolute($top: 0, $right: 0);
  }
}
