.modal-deck-analytics {

  .overall {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 2rem;

      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .25rem;

        .card-data {
          display: flex;
          gap: .5rem;
          align-items: center;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }

        small {
          color: var(--gray-dark);
        }
      }
    }

    .chart {
      display: flex;
      height: 330px;
      flex-direction: column;
      justify-content: space-between;
      margin: 1rem;
      padding: .5rem;
      border-radius: .25rem;
      background-color: var(--gray-lighter);

      canvas {
        width: 60%;
      }

      .doughnut {
        display: flex;
        justify-content: space-between;
        margin: 3rem 0;

        div {
          display: flex;
          width: 50%;
        }
      }
    }
    .chart-breakdown {
      @extend .chart;
      height: 380px;

      canvas {
        width: 70%;
      }
    }
    
    .vl {
      border-left: 1px solid var(--gray-light);
      height: 50px;
      left: 50%;
      margin-left: -3px;
      top: 0;
    }
  }
}
