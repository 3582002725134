@import '@szhsin/react-menu/dist/index.css';
@import '@szhsin/react-menu/dist/transitions/slide.css';

.szh-menu__item {
  padding: .5rem 1rem;
  color: var(--gray-darkest);

  &--hover {
    background-color: var(--headeer-bar-color);
    color: var(--white);
  }

  svg {
    height: 1rem;
    margin-right: .5rem;
  }
}

.szh-menu__radio-group {
  width: 100%;

  .szh-menu__item {
    padding: .5rem 1.5rem .5rem 2.25rem;

    &::before {
      top: .5rem;
    }
  }
}
