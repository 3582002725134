.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-button-color);
  border-radius: 5px;
  padding: 0 5px;
  width: fit-content;
  height: auto;
  color: var(--white);
}