div.signer .send-client-container{
  width: 105%;
  float: left;
}
.send-client-container {
    width: 49%;
    float: left;

  .reach-text-container .label{
    top: 12px;
  }
  .autocomplete_wrapper .label{
    top: -9px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    gap: 2.5rem;
    // padding: 1.25rem 0;

    > div {
      width: 100%;
    }

    > .label {
      width: 90%;
      display: block;
      align-items: center;
      color: var(--gray-dark);

      > svg {
        margin-right: .5rem;
        width: 15px;
        height: 15px;
      }
      .spinner{
        text-align: center;
      }
    }

    > .value {
      flex: 1;
      display: grid;
      align-items: center;
    }
  }

  .send-client-body {
    margin-top: 1rem;

    .signer {
      display: flex;
      align-items: flex-start;
      color: var(--gray-darker);
    }

    small {
      color: var(--secondary);
    }
  }

  .input-contain .label{
    top: -10px;
    z-index: 0;
  }

  .text-area-contain .label{
    top: -12px;
    z-index: 0;
    left: 0px;
    padding: 0 0.5rem;
  }

  .send-client-email {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0;
  }
  .text {
    position: relative;
    transform: translateY(-50%);
    padding: 0 0.5rem;
    color: var(--gray-dark);
    z-index: 999;
    top: 10px;
    left: 19px;
    background-color: white;
  }
  
}
.sentClient{
  div.value{
    width: 49%;
    float: left;
  }
  
}
