.app {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-lighter);

  > .transition-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > [class^="page-"] {
      flex: 1;
      overflow: hidden;
    }
  }
}
