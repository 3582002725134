.input-contain {
  width: 100%;
  position: relative;

  > input {
    height: 42px;
    padding: 0 1.25rem;
    border: 1px solid var(--gray-light);
    border-radius: 4px;

    &:focus {
      border-color: var(--primary);
    }

    &:focus + .label {
      .text {
        color: var(--gray-darkset);
      }
    }
  }

  .label {
    @include absolute($top: 0, $left: 10px);
    z-index: 1;

    .text {
      position: relative;
      transform: translateY(-50%);
      padding: 0 .5rem;
      color: var(--gray-dark);

      &.focused {
        color: var(--gray-darkset);
      }

      &::after {
        @include absolute($top: 48%, $left: 0);
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--white);
        z-index: -1;
      }
    }
  }
}

input[type='date']::before {
  @include absolute();
  content: attr(placeholder);
  color: var(--gray-darker);
}

input[type='date'] {
  color: var(--white);
}

input[type='date']:focus,
input[type='date']:valid {
  color: var(--gray-darkest);
}

input[type='date']:focus::before,
input[type='date']:valid::before {
  content: '';
}
