.layout-experience {
  width: 100%;
  height: 100%;

  .flex-wrapper {
    align-items: center;
    justify-content: space-around;

    @include tablet-lg {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 45%;
      max-height: 100%;
      overflow: hidden;

      @include tablet-lg {
        height: auto;
        width: 100%;
      }

      > div {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > h3 {
          color: var(--title-text-color);
          padding: 1.75rem 0;
        }

        @include tablet-lg {
          height: auto;

          > h3 {
            padding: 1rem 0;
          }

          > .body {
            max-height: 110px;
            overflow: hidden;
          }
        }
      }

      > h5 {
        color: var(--title-text-color);
        padding: 3.75rem 0;
      }

      @include tablet-lg {
        > h5 {
          display: none;
        }
      }
    }

    .right {
      width: 100%;
      overflow: hidden;
      margin-top: .5rem;

      .company-container {
        overflow: auto;
        height: auto;
        max-height: 380px;
        border: 1px solid #ECEDF2;
      }
    }
  }
}
