.reach-text-container {
  position: relative;

  .label {
    @include absolute($top: 22px, $left: 10px);
    transform: translateY(50%);
    z-index: 1;

    .text {
      position: relative;
      transform: translateY(-50%);
      padding: 0 .5rem;
      color: var(--gray-dark);

      &.focused {
        color: var(--gray-darkset);
      }

      &::after {
        @include absolute($top: 10px, $left: 0);
        content: '';
        width: 100%;
        height: 2px;
        background-color: rgb(255, 255, 255);
        border-color: white;
        z-index: -1;
      }
    }
  }

  .ql-toolbar {
    display: flex;
    justify-content: flex-end;
    border: 0;
    padding-right: 0;
    padding-top: 0;

    .ql-formats {
      margin-right: 0;
    }
  }

  .ql-container {
    border: 0;
    height: 220px;

    .ql-editor {
      border: 1px solid var(--gray-light);
      padding: 1.25rem;
      background-color: var(--white);
      border-radius: 4px;

      &:focus {
        border: 1px solid var(--primary);
      }

      &::before {
        @include absolute($top: 1px, $right: 1.25rem, $left: 1px);
        content: '';
        height: 1.25rem;
        border-radius: 4px;
        background-color: var(--white);
      }
    }
  }

  .limit-info {
    text-align: right;
    display: block;
    padding: .25rem .25rem .5rem 0;
    color: var(--gray-darker);
  }

  .limit-error {
    text-align: right;
    display: block;
    padding: .25rem .25rem .5rem 0;
    color: $state-error;
  }

  .ql-tooltip {
    left: -.5rem !important;
    z-index: 1;
  }
}
