.layout-global {

  .flex-wrapper {
    @include tablet-lg {
      flex-direction: column;
      justify-content: space-evenly;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .left {
      width: 200px;
      height: 100%;

      @include tablet-lg {
        width: 100%;
        height: 200px;
      }

      h3 {
        color: var(--title-text-color);
        margin-bottom: 1rem;
      }

      h6 {
        color: var(--alternative-2);
        margin-top: 1rem;
      }
    }

    .right {
      flex: 1;

      @include tablet-lg {
        flex: none;
        max-height: calc(var(--vh) * 45);
      }

      img {
        float: left;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    svg .map {
      opacity: .4;
    }

    svg > g > g > path {
      cursor: pointer;
      fill: var(--secondary-strong);
      stroke-width: 13.5px;
      stroke: transparent;
      transition: fill .35s linear;

      &:hover {
        fill: var(--primary);
      }
    }

    svg > g > path {
      fill: var(--primary-light);
      fill-rule: evenodd;
      isolation: isolate;
      opacity: .75;
    }
  }
}
