@import './_variables.scss';

// ====== Aspect ratio
// ======================================

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: calc($height / $width) * 100%;
  }

  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// ====== Padding
// ======================================

@mixin set-padding($pH: 1.5rem, $pV: 1.5rem) {
  padding: $pH $pV;
}

// ====== Positions
// ======================================

@mixin full-absolute() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin full-fixed() {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin absolute($args...) {
  @include position(absolute, $args...);
}

@mixin relative($args...) {
  @include position(relative, $args...);
}

@mixin fixed($args...) {
  @include position(fixed, $args...);
}

@mixin sticky($args...) {
  @include position(sticky, $args...);
}

// ====== Text ellipsis
// ======================================

@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin multiLineEllipsis($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// ====== Media queries
// ======================================

@mixin mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet-lg {
  @media (max-width: $breakpoint-tablet-lg) {
    @content;
  }
}
// todo delete breakpoints min-width
@mixin desktop {
  @media (min-width: $breakpoint-tablet-lg) {
    @content;
  }
}

@mixin md-desktop {
  @media (min-width: $breakpoint-desktop-md) {
    @content;
  }
}

@mixin desktop-lg {
  @media (max-width: $breakpoint-desktop-lg) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

// ====== Keyframes
// ======================================

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
