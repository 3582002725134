.crop-image {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  padding: .5rem;
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 400px;
  margin: 15px;
  border: 2px solid var(--alternative);
  border-radius: .5rem;

  .cropper-container {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}

.crop-image-footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ReactCrop {
  border-radius: .5rem;
  img {
    max-height: 380px;
  }
}