.gallery-crop-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  gap: .75rem;
  padding: 0 1.75rem;
  border-radius: .25rem;
  transition: all .25s linear;
  font-weight: 600;

  &.primary,
  &.secondary {
    text-transform: uppercase;
  }

  &[disabled] {
    cursor: default;
  }

  &.primary {
    background-color: var(--primary-strong);
    color: var(--white);

    &:hover {
      background-color: var(--primary);
    }

    &:focus,
    &:active {
      background-color: var(--primary-strong);
    }

    &[disabled] {
      background-color: var(--gray-dark);
      color: var(--white);
    }
  }

  &.secondary {
    border: 1px solid var(--primary);
    color: var(--primary);

    &:hover {
      border-color: var(--primary-strong);
      color: var(--primary-strong);
    }

    &[disabled] {
      background-color: var(--white);
      border-color: var(--gray-dark);
      color: var(--gray-dark);
    }
  }

  &.alternative {
    color: var(--primary);

    &:focus {
      color: var(--primary-strong);
    }

    &.contrast {
      color: var(--white);

      &:focus {
        color: var(--gray-light);
      }
    }

    &[disabled] {
      background-color: transparent;
      border-color: var(--gray-dark);
      color: var(--gray-dark);
      text-decoration: none;
    }

    &.selected {
      background-color: var(--gray-light);
    }
  }

  &.icon-only {
    width: 45px;
    min-width: 45px;
    padding: 0;
  }

  &.transparent {
    background-color: transparent;
    min-height: 30px;
    text-transform: uppercase;
    color: var(--primary);
    padding: 0 .5rem;
  }
}
