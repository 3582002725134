.crop-gallery-card {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  margin: auto;
  border-radius: .5rem;

  .card-body {
    width: 100%;
    height: 100%;
  }

  .card-title {
    margin-bottom: 1rem;
  }

  .card-buttons-container {
    padding: 1.5rem;
  }
}
