.category-dropdown {
  margin: 0;

  .imports {
    display: flex;
    gap: .5rem;
  }

  .dropdown {
    padding: 1rem;
    margin: 0 -1.25rem;

    button {
      margin-top: 1rem;
    }
  }

  button {
    padding: 0 1rem;
    margin: 1rem 0;
  }

  hr {
    margin: 0 -1.25rem;
    background-color: var(--gray-light);
    color: var(--gray-light);
    height: 1px;
    border: 0;
  }
}
