.video-recorder {
  .video-recorder-control {
    display: flex;
    margin: 1rem 0;

    &.spaced {
      justify-content: space-between;
    }

    &.centered {
      justify-content: center;
    }
  }

  .video-recorder-title {
    margin-bottom: 1.5rem;
  }

  .video-recorder-camera-previewer {
    height: auto;
    border: 3px solid var(--warning);
    border-radius: .5rem;
  }

  .video-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .video-previewer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20rem;
      width: 100%;
      border: 3px solid var(--warning);
      border-radius: 0.5rem;
      margin: 1rem 0;
    }
  }

  .video-recorder-record-time {
    font-size: .75rem;
  }

  .video-recorder-recording-time {
    margin-top: 1rem;
  }
}
