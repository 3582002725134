.process-step-icon {
  @include aspect-ratio(1, 1);
  width: 100%;
  height: 100%;

  .order-svg {
    @include absolute($top: 0, $left: 0);
    transform: translateX(-20%);
  }

  text {
    stroke: var(--white);
    stroke-width: .5px;
    filter: drop-shadow(-1px 0 2px rgba($black, .3));
  }

  &.step-1 svg {
    path,
    text {
      fill: var(--alternative-3);
    }
  }

  &.step-2 svg {
    path,
    text {
      fill: var(--alternative-1);
    }
  }

  &.step-3 svg {
    path,
    text {
      fill: var(--secondary);
    }
  }

  &.step-4 svg {
    path,
    text {
      fill: var(--primary);
    }
  }
}
