.tag {
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding: 2px 6px;
  border-radius: .25rem;
  background-color: var(--gray-light);
  white-space: nowrap;
  line-height: 1;
  color: var(--gray-darker);
}
