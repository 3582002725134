.page-deck {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;

  .loading-cover {
    @include full-absolute;
    background-color: var(--gray-lighter);
    z-index: 5;

    &.loaded {
      animation: anim-loading .5s linear .5s forwards;
    }
  }
}

@include keyframes(anim-loading) {
  99% { opacity: 0 }
  100% {
    visibility: hidden;
    z-index: -1;
  }
}
