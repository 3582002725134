.layout-candidates {

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    @include tablet-lg {
      flex-direction: column;
      gap: 1rem;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 35%;
      height: 100%;

      > h3 {
        color: var(--title-text-color);
        padding: 1rem 0;
      }

      .content {
        height: 100%;

        @include tablet-lg {
          max-height: 80px;
        }
      }

      @include tablet-lg {
        width: 100%;
        height: auto;
        
        > hr {
          display: none;
        }

        :nth-child(4) {
          display: none;
        }
      }
    }

    .right {
      overflow: hidden;
      width: 70%;

      @include tablet-lg {
        width: 100%;
      }
    }
  }

  .swiper-slide{
    width: 295px !important;
  }
}
