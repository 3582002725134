*,
*::before,
*::after {
  font-family: var(--font);
  font-weight: 500;
  color: var(--darkest);
}

[class^="brand-"] {
  color: var(--body-text-color);
}

[class^="layout-"] {
  padding: 5.5rem 2.25rem 2.25rem;
  overflow: unset !important;

  @include tablet-lg {
    padding: 5rem 1rem 1rem;
  }

  > .wrapper,
  > .flex-wrapper {
    max-width: $max-screen-width;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  > .flex-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  > .background {
    @include absolute($top: 0, $right: 0, $bottom: 0, $left: calc(-100% - 1px));
    z-index: -1;
    transform: translateX(calc(50% - #{$sidebar-width} + (100vw - #{$breakpoint-desktop-lg}) / -2));

    @include desktop-lg {
      transform: translateX(calc(50% - #{$sidebar-width}));
    }
  }
}

.kgp-tooltip {
  background-color: var(--primary-strong) !important;
  padding: .5rem .75rem !important;
  color: var(--white) !important;
  font-weight: 600;

  &::before,
  &::after {
    opacity: 0;
  }
}

img[src=""] {
  background-color: var(--gray-light);
}

// ====== Inputs
// ======================================

.input-textarea-wrapper {
  position: relative;
  margin: 1.25rem 0;

  label {
    @extend .body;
    @include absolute($top: 0, $left: 1rem);
    transform: translateY(-50%);
    padding: 0 .5rem;
    background-color: var(--white);
  }

  textarea {
    width: 100%;
    height: auto;
    min-width: 4rem;
    resize: vertical;
    border-radius: .25rem;
    border: 1px solid var(--primary);
    padding: .75rem 1.25rem;
  }
}

// ====== Backgrounds
// ======================================

.layout-team,
.layout-signature-document,
.layout-signature,
.layout-media.video {
  position: relative;

  .bg-mosaic {
    @include absolute($top: 0, $left: 50%);
    height: 100%;
    z-index: -1;

    @include tablet-lg {
      display: none;
    }
  }
}

.layout-media.video.end {
  overflow: hidden !important;

  .bg-mosaic {
    left: unset;
    right: 50%;
  }
}

.layout-experience,
.layout-source-environment {
  position: relative;

  .shape-1,
  .shape-2,
  .shape-3 {
    @include absolute();
    z-index: -1;

    @include tablet-lg {
      display: none;
    }
  }

  .shape-1 {
    width: 25%;
    bottom: 0;
    left: 0;
  }

  .shape-2 {
    width: 25%;
    right: 0;
    bottom: 0;
  }

  .shape-3 {
    height: 45%;
    top: 10%;
    right: 0;
  }
}

.layout-candidates {
  position: relative;
  overflow: hidden !important;

  .shapes {
    @include absolute($bottom: 0, $left: -7%);
    z-index: -1;
    width: 60%;

    @include tablet-lg {
      display: none;
    }
  }
}
