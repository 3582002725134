.my-profile-menu {
  width: 300px;
  padding: 0;
  border-radius: .75rem;
  background-color: var(--gray-lighter);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;
    background: var(--primary) 50% 50% / cover;
    color: var(--white);
    border-radius: .5rem .5rem 0 0;

    .button.icon-only {
      color: var(--white);
    }
  }

  .content {
    display: inline-block;
    width: 100%;
    padding: 1rem 1.25rem;

    button {
      margin-top: 1rem;
      float: right;
    }
  }
}
