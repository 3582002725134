.file-picker-wrapper {
  .file-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    margin-top: .5rem;
    background-color: var(--white);
    border: 1px solid var(--gray-light);
    border-radius: 0.25rem;

    .file-wrapper {
      display: flex;
      align-items: center;

      .file-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2rem;
        margin: 0 0.5rem;
        background-color: var(--gray-light);
        border-radius: 0.25rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      small {
        @include text-ellipsis;
        max-width: 150px;
      }
    }

    .signed {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 0.5rem;
      color: var(--success);
    }
  }

  .file-button {
    display: flex;
    cursor: auto;
    margin: .5rem 0 1rem;
  }

  .addSigners {

    button {
      margin-top: .5rem;
    }

    .input-signer {
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;
      align-items: center;
      height: 50px;
      margin: 0 -1.25rem;

      input {
        margin: 0 1rem;
      }

      .actions {
        display: flex;
        gap: .5rem;
        align-items: center;
        color: var(--gray-dark);
        cursor: pointer;

        &:first-child {
          svg {
            color: var(--gray-light);
          }
          cursor: default;
        }

        svg {
          width: 22px;
        }
      }
    }
  }
}
