.push-notification {
  width: 370px;

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    min-height: 370px;
    padding: .75rem 1.5rem;
    overflow: hidden;

    svg {
      width: 100px;
      height: 100px;
      color: var(--gray-light);
    }

    h6 {
      margin: 1rem 1rem;
    }

    p {
      text-align: center;
    }
  }

  .push-notification-header {
    padding: 1rem 1.25rem;
  }

  .push-notification-body {
    padding-top: 0;
    height: 100%;
    max-height: calc(var(--vh) * 65);
    overflow-y: auto;
  }

  .notification {
    padding: .75rem 1.5rem;
    transition: all .25s ease;
    cursor: pointer;

    .notification-title {
      color: var(--primary);
    }

    .notification-body {
      color: var(--gray-darker);
    }

    > p {
      text-align: left;
    }

    &:hover {
      background-color: var(--gray-lighter);
    }
  }

  .notification-link {
    .deck-id {
      @include text-ellipsis;
      width: 200px;
    }

    color: var(--primary);
    text-align: left;
    display: flex;
    flex: 1;
    gap: 2.5px;
  }
}
