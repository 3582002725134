.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 45px;
  gap: .75rem;
  padding: 0 1.75rem;
  border-radius: .25rem;
  transition: all .25s linear;

  @include tablet-lg {
    height: 38px;
    gap: .5rem;
    padding: 0 1.5rem;
  }

  &.default,
  &.alternative {
    text-transform: uppercase;
  }

  &[disabled] {
    cursor: default;
  }

  &.default {
    background-color: var(--primary);
    color: var(--white);

    &:hover {
      background-color: var(--primary-light);
    }

    &:focus,
    &:active {
      background-color: var(--primary-strong);
    }

    &:active {
      color: var(--primary-light);
    }

    &[disabled] {
      background-color: var(--gray-dark);
      color: var(--white);
    }
  }

  &.alternative {
    background-color: var(--white);
    border: 1px solid var(--secondary-button-color);
    color: var(--primary);

    &:hover {
      background-color: var(--secondary-button-color);
    }

    &:focus {
      border-width: 2px;
    }

    &:active {
      background-color: var(--primary-strong);
      color: var(--white);
    }

    &[disabled] {
      background-color: var(--white);
      border-color: var(--gray-dark);
      color: var(--gray-dark);
    }
  }

  &.link {
    color: var(--primary);
    border: 1px solid transparent;

    &.contrast {
      color: var(--white);
    }

    &:focus {
      border-color: var(--primary);

      &.contrast {
        border-color: var(--white);
      }
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      background-color: var(--primary-light);
      text-decoration: underline;
    }

    &[disabled] {
      background-color: transparent;
      border-color: var(--gray-dark);
      color: var(--gray-dark);
      text-decoration: none;
    }
  }

  &.icon-only {
    width: 45px;
    min-width: 45px;
    padding: 0;

    @include tablet-lg {
      width: 38px;
      min-width: 38px;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &.small {
    min-height: auto;
    height: auto;
    line-height: 1rem;
    gap: .5rem;
    padding: .5rem 1rem;
    font-size: 12px;
  }

  svg {
    height: 18px;
    pointer-events: none;

    @include tablet-lg {
      height: 16px;
    }
  }
}
