.video-picker {
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  .header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
  }

  .action {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;

    button {
      padding: 0 .5rem;
    }
  }

  input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
