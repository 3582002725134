$footer-height: 3rem;

.table {
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray-mid);
  border-radius: .5rem;
  box-shadow: $shadow-mid;
  background-color: var(--white);
  overflow: auto;

  > .wrapper {
    float: left;
    width: 100%;
    height: calc(100% - #{$footer-height});
    overflow: auto;
  }

  &.alternative {
    box-shadow: none;
    border: 0;

    > .wrapper {
      height: 100%;
    }
  }

  table {
    position: relative;
    float: left;
    min-width: 100%;
    width: auto;
    max-height: 100%;

    thead {
      color: var(--gray-darkest);
    }

    th {
      position: sticky;
      z-index: 13 !important;
      top: 0;
      height: 53px;
      padding: 0 20px 14px;
      vertical-align: bottom;
      text-align: left;
      background-color: var(--white);

      > div {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .options {
        display: inline;

        &:not(:empty){
          margin-left: .5rem;
        }

        svg {
          float: right;
          color: var(--gray-mid);
          cursor: pointer;
          transition: all .2s linear;

          &:not(.selected):hover {
            color: var(--gray-darker);
          }

          &.sort {
            height: 17px;
            transform-origin: center;

            &.desc {
              transform: rotate(180deg);
            }
          }

          &.filter {
            height: 11px;
          }

          &.selected {
            color: var(--secondary-strong);
          }
        }
      }
    }

    tbody {
      padding-top: .5rem;
      color: var(--gray-darker);

      tr {
        position: relative;

        &:hover td {
          background-color: var(--gray-lighter);
        }
      }
    }

    .column-switcher {
      display: inline-block;
      height: 1.25rem;
      transform-origin: center;
      transform: translateY(6px) rotate(90deg);
    }

    .menu-buttons {
      @include sticky($right: 0);
      width: 2.25rem;
      min-width: 2.25rem;
      z-index: 2;
      padding: 0;
      background-color: var(--white);
      vertical-align: middle;

      svg {
        height: 1.25rem;
        cursor: pointer;
      }
    }

    th.menu-buttons {
      z-index: 8;
    }

    td {
      height: 44px;
      padding: 0 1.25rem;
      transition: background-color .15s linear;
    }

    .check {
      @include sticky($left: 0);
      z-index: 6;
      padding: 0;
      background-color: var(--white);
      max-width: 28px;
      min-width: 28px;
      width: 28px;
      text-align: center;

      .checkbox-wrapper {
        width: auto;
        display: inline-flex;
        margin: auto;
      }
    }

    th.check {
      display: flex;
    }
  }

  .loading {
    position: absolute;
    text-align: center;

    &:hover td {
      background-color: var(--white);
      max-width: 28px;
      min-width: 28px;
      width: 28px;
      text-align: center;

      .checkbox-wrapper {
        display: inline-flex;
        margin: auto;
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $footer-height;
    padding: 0 1.25rem 0 .75rem;
    background-color: var(--gray-lighter);
    color: var(--gray-darkest);

    > div {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .info {
      display: inline-block;
      margin-right: 1.5rem;
    }

    .count-selected {
      margin-left: 1rem;
    }

    svg {
      height: 1rem;
      cursor: pointer;
    }
  }

  .select-column {
    background: transparent;
    height: 100%;
    padding: .25rem .75rem;
    border: 1px solid var(--primary);
    border-radius: 4px;
    transition: all .25s linear;

    &.marked {
      background: var(--primary);
      color: var(--white);
    }
  }
}
