.deck-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  color: var(--toolbar-button);
  // background-color:  var(--headeer-bar-color);
  // opacity: 0.5;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .5rem;

    > .menu-container {
      width: 7rem;
      display: flex;
      justify-content: space-between;

      span {
        cursor: pointer;
        transition: color .25s linear;
      }

      &.clean > :last-child,
      &.disabled span {
        pointer-events: none;
        cursor: default;
        color: var(--gray-dark);
      }
    }

    .deck-name {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        min-width: 0;
        width: auto;
        margin-left: .25rem;
        padding: .25rem;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    width: 30rem;

    .buttons-inner {
      display: flex;
      gap: .5rem;
    }

    > svg {
      cursor: pointer;
    }
  }
}
