$base-url: 'https://d3jnaxn7oyudmi.cloudfront.net/fonts/';

// ====== Font faces
// ======================================

$font: 'Satoshi';
$font-open: 'OpenSans';
$font-bitter: 'Bitter';
$font-roboto: 'Roboto';

@font-face {
  font-family: $font;
  src: url($base-url + $font + '-Black.eot');
  src: url($base-url + $font +'-Black.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font + '-Black.woff2') format('woff2'), url($base-url + $font + '-Black.woff') format('woff'),
    url($base-url + $font + '-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $font;
  src: url($base-url + $font + '-Bold.eot');
  src: url($base-url + $font +'-Bold.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font + '-Bold.woff2') format('woff2'), url($base-url + $font + '-Bold.woff') format('woff'),
    url($base-url + $font + '-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $font;
  src: url($base-url + $font + '-Medium.eot');
  src: url($base-url + $font +'-Medium.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font + '-Medium.woff2') format('woff2'), url($base-url + $font + '-Medium.woff') format('woff'),
    url($base-url + $font + '-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// @todo: validate if we will really need the 400 weight variant - else remove it
@font-face {
  font-family: $font;
  src: url($base-url + $font + '-Regular.eot');
  src: url($base-url + $font +'-Regular.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font + '-Regular.woff2') format('woff2'), url($base-url + $font + '-Regular.woff') format('woff'),
    url($base-url + $font + '-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


//For OpenSans
@font-face {
  font-family: $font-open;
  src: url($base-url + $font-open + '-Black.eot');
  src: url($base-url + $font-open +'-Black.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-open + '-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $font-open;
  src: url($base-url + $font-open + '-Bold.eot');
  src: url($base-url + $font-open +'-Bold.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-open + '-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family:$font-open;
  src: url($base-url + $font-open + '-Medium.eot');
  src: url($base-url + $font-open +'-Medium.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-open + '-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// @todo: validate if we will really need the 400 weight variant - else remove it
@font-face {
  font-family: $font-open;
  src: url($base-url + $font-open + '-Regular.eot');
  src: url($base-url + $font-open +'-Regular.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-open + '-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

//For Bitter
@font-face {
  font-family: $font-bitter;
  src: url($base-url + $font-bitter + '-Black.eot');
  src: url($base-url + $font-bitter +'-Black.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-bitter + '-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $font-bitter;
  src: url($base-url + $font-bitter + '-Bold.eot');
  src: url($base-url + $font-bitter +'-Bold.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-bitter + '-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family:$font-bitter;
  src: url($base-url + $font-bitter + '-Medium.eot');
  src: url($base-url + $font-bitter +'-Medium.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-bitter + '-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// @todo: validate if we will really need the 400 weight variant - else remove it
@font-face {
  font-family: $font-bitter;
  src: url($base-url + $font-bitter + '-Regular.eot');
  src: url($base-url + $font-bitter +'-Regular.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-bitter + '-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Procreate-Signature';
  src: url($base-url + 'Procreate-Signature.otf');
  font-weight: 400;
  font-style: normal;
}

//For Roboto
@font-face {
  font-family: $font-roboto;
  src: url($base-url + $font-roboto + '-Black.eot');
  src: url($base-url + $font-roboto +'-Black.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-roboto + '-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: $font-roboto;
  src: url($base-url + $font-roboto + '-Bold.eot');
  src: url($base-url + $font-roboto +'-Bold.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-roboto + '-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $font-roboto;
  src: url($base-url + $font-roboto + '-Medium.eot');
  src: url($base-url + $font-roboto +'-Medium.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-roboto + '-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// @todo: validate if we will really need the 400 weight variant - else remove it
@font-face {
  font-family: $font-roboto;
  src: url($base-url + $font-roboto + '-Regular.eot');
  src: url($base-url + $font-roboto +'-Regular.eot?#iefix') format('embedded-opentype'),
    url($base-url + $font-roboto + '-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

// ====== Heading tags and texts
// ======================================

h1 {
  font-size: 100px;
  font-weight: 700;

  @include tablet-lg {
    font-size: 42px;
  }
}

h2 {
  font-size: 92px;
  font-weight: 600;

  @include tablet-lg {
    font-size: 35px;
  }
}

h3 {
  font-size: 50px;
  font-weight: 600;

  @include tablet-lg {
    font-size: 29px;
  }
}

h4 {
  font-size: 41px;
  font-weight: 500;

  @include tablet-lg {
    font-size: 24px;
  }
}

h5 {
  font-size: 31px;
  font-weight: 500;

  @include tablet-lg {
    font-size: 20px;
  }
}

h6 {
  font-size: 21px;
  font-weight: 500;

  @include tablet-lg {
    font-size: 17px;
  }
}

.body {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;

  &.light {
    color: var(--dark);
  }

  &.strong {
    font-weight: 600;
  }

  @include tablet-lg {
    font-size: 14px;
  }
}

.body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  &.light {
    color: var(--dark);
  }

  &.strong {
    font-weight: 600;
  }

  @include tablet-lg {
    font-size: 12px;
  }
}

.caption {
  font-weight: 500;
  line-height: 134%;

  &.light {
    color: var(--dark);
  }

  &.strong {
    font-weight: 700;
  }

  @include tablet-lg {
    font-size: 12px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.strong {
  font-weight: 600;
}
