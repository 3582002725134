.slide-form {
  width: $slides-form-width;
  height: 100%;
  padding: 1.25rem;
  background-color: linear-gradient(0deg, var(--gray-lighter), var(--gray-lighter)), linear-gradient(0deg, var(--gray-light), var(--gray-light));
  border: 1px solid var(--gray-light);
  overflow-y: auto;

  > h6 {
    margin-bottom: 1.25rem;
    color: var(--headeer-bar-color);
  }
}
