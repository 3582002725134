.top-system-setting{
  .modal-base .container .modal-body {
    border: 1px solid var(--headeer-bar-color);
  }

.modal-system-setting {
  
  height: calc(var(--vh) * 60);
	.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    gap: 2.5rem;
    // padding: 1.25rem 0;

    > div {
      width: 100%;
    }

    > .label {
      width: 20%;
      // display: flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--gray-dark);
      border-right: 2px solid var(--gray-dark);

      > svg {
        margin-right: .5rem;
        width: 15px;
        height: 15px;
        color: var(--gray-dark);
        margin-left: 20px;
      }

      .label-container{
        width: 100%;
        height: 10%;

        .selected-theme{
          color: var(--headeer-bar-color);
          // padding-bottom: 5px;
          border-bottom-style: solid;
          border-bottom-width: 2.1px;
          width: fit-content;
        }
      }
      .label-container-email{
        width: 100%;
        height: 10%;

        .selected-theme{
          color: var(--headeer-bar-color);
          // padding-bottom: 5px;
          border-bottom-style: solid;
          border-bottom-width: 2.1px;
          width: fit-content;
        }
      }
    }

    > .value {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
}
}