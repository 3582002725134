.sentClient .value > div.input-wrapper:last-child{
    margin: 0;
}
.title-field-information{
    color: red;
    font-size: 13px;
}

div[data-attr="title"] {
    margin: 0;
}