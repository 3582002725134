.layout-team {
  .flex-wrapper {
    @include tablet-lg {
      flex-direction: column;
      height: auto;
      gap: 4rem;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 35%;
      height: 100%;

      @include tablet-lg {
        width: 100%;
      }

      > div {
        @include tablet-lg {
          height: 200px;
        }
      }

      h3 {
        color: var(--title-text-color);
        padding: 1.75rem 0;
      }

      @include tablet-lg {
        > h3 {
          padding: 1rem 0;
        }
      }
    }

    .right {
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;

      @include tablet-lg {
        display: block;
        width: 100%;
        flex: 1;
      }

      > .grid {
        grid-gap: 1rem;
        display: grid;
        align-content: center;
        float: left;
        gap: 2rem;
        grid-auto-rows: auto;
        grid-template-columns: repeat(4, minmax(auto, 145px));
        max-height: 650px;
        height: 100%;
        overflow: hidden;
        width: 100%;

        &.partner-side {
          gap: 2rem;
          grid-auto-rows: auto;
          grid-template-columns: repeat(4, minmax(auto, 105px));

          > div {
            margin-bottom: 30px;
          }
        }

        @include tablet-lg {
          width: 100%;
        }

        > div {
          display: flex;
          flex-direction: column;
          position: relative;
          margin-bottom: 40px;
          cursor: pointer;
          max-height: 250px;

          .details {
            left: 0;
            right: 0;
            position: initial;
            bottom: -50px;
            margin-top: .5rem;
            text-align: center;

            div,
            p {
              line-height: 1.21;
            }

            div {
              padding: .75rem 0 .25rem;
              color: var(--darkest);
            }

            p {
              color: var(--gray-dark);
            }
          }

          &:hover {
            div {
              color: var(--title-text-color);
              transition: all .5s ease-in-out;
            }
          }
        }
      }
    }
  }
}
