.modal-file-uploader {

  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    height: calc(var(--vh) * 30);
    background-color: var(--gray-lighter);
    border: 3px var(--gray-dark) dashed;
    border-radius: .5rem;

    .text-center {
      text-align: center;
      margin: 1rem;
      
      .grey-font{
        color: var(--gray-dark);
      }
    }

    b {
      font-weight: bold;
    }
  }

  .subTitle {
    margin: 1rem 0;
  }

  .header-title {
    padding: 1rem;
    color: var(--primary);
    background-color: var(--gray-lighter);
    border-radius: .75rem;

    .header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ul {
        padding-left: .5rem;
      }

      li {
        margin: .5rem;
        list-style-type: disc;
      }

      .download-template {
        display: flex;
        align-items: center;

        svg {
          margin-right: .5rem;
        }
      }
    }

    p {
      font-weight: bold;
    }
  }
  .custom-form{
    display:flex;
    align-items: center;
    justify-content: space-between;
  }
  .mapping-body{
    display: flex;
    flex-direction: column;
  }
  .custom-mapping{
    margin:0 20px;
    border-left: 2px solid var(--gray-darkest);
   
  }
  .input-wrapper {
    margin: 0.25rem 0 !important;
}

.form-field-section{
  height:140px;
  overflow-y: auto;
}

.autocomplete_wrapper .select-container .autocomplete__control {
  min-height: 38px !important;
}
  .mapped-table{
    height: 250px;
  }
  .form-header{
    font-size: 15px;
    font-weight: 600;
  }
  .font-lighter{
    font-weight: lighter;
  }
  .field{
    padding:10px;
  }
  .mapping-title{
    padding: 20px;
    border-bottom: 2px solid var(--gray-light);
    background-color: var(--gray-lighter);
    color:var(--gray-darkest)
  }
  .mapping-title h6{
    background-color: white;
    display: inline-block;
  }
  .container-form{
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--gray-light);
  }
  .border-form{
    border-top: 5px solid var(--title-text-color);
  }
  .column{
    flex: 50%;
  }
  .form-layout{
    margin:0 20px;
  }

  .table-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > :first-child {
      color: $state-error;
    }

    > :last-child {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }
}