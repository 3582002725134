.expansible {
  position: relative;
  overflow: hidden;

  .hider {
    @include absolute($left: 0, $bottom: 0);
    width: 100%;
    height: 2.25rem;
    background: linear-gradient(0deg, rgba($white, 1) 0%, rgba($white, .75) 66%, rgba($white, 0) 100%);
  }

  .show-more {
    @include absolute($left: 0, $bottom: 0);
    text-align: left;
    text-shadow: 2px 2px var(--white);
    font-weight: 600;
    color: var(--secondary);
    cursor: pointer;
  }
}
