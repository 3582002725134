.input-dropdown {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  height: 50px;
  margin: 0 -1.25rem;
  border: 1px solid var(--gray-light);

  input {
    margin: 0 1rem;
  }
  .subcategory{
    margin-left: 30px;
  }

  .actions {
    display: flex;
    gap: .5rem;
    align-items: center;
    color: var(--gray-dark);
    cursor: pointer;

    &:first-child {
      svg {
        path {
          fill: var(--gray-dark);
        }
      }
    }

    svg {
      width: 22px;
    }
  }

  .sub-actions {
    display: flex;
    gap: .5rem;
    align-items: center;
    color: var(--gray-dark);
    cursor: pointer;

    &:first-child {
      svg {
        path {
          fill: var(--gray-dark);
        }
      }
    }

    svg {
      width: 22px;
    }
  }
}
.subcategory{
  padding-left: 30px;
}
.subcategory-company{
  padding-left: 60px;
}