.react-tabs {
  overflow: hidden;

  &__tab-list {
    margin: 0 0 1.5rem;
    overflow-x: scroll;
    list-style: none;
    display: block;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      width: 0 !important
    }
  }

  &__tab {
    display: inline-block;
    border-bottom: none;
    position: relative;
    list-style: none;
    padding: .5rem;
    margin: 0 .5rem;
    cursor: pointer;
    color: var(--gray-dark);

    &--selected {
      border-bottom: 2px solid;
      border-color: var(--headeer-bar-color);
      color: var(--dark);
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
