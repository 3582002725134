.tag-input {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  .tag-element {
    display: flex;
    align-items: center;
    background-color: var(--gray-lighter);
    padding: 2px .5rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color .15s linear;

    svg {
      margin-right: .25rem;
      width: 15px;
      height: 15px;
    }
  }
}
