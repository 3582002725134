.simple-company-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  .card {
    @include aspect-ratio(13, 6);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius: .25rem;
    border: 1px var(--gray-mid) solid;
    box-shadow: $shadow-mid;
    overflow: hidden;
    cursor: pointer;
    transition: border-color .25s linear;

    &:hover {
      border-color: var(--headeer-bar-color);
    }

    img {
      height: 100%;
      object-fit: fill;
      pointer-events: none;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .name {
      @include text-ellipsis;
      width: 100%;
      padding: 0 .5rem .25rem;
      text-align: center;
      color: var(--darkest);
    }
  }

  @include tablet-lg {
    .card {
      hr {
        display: none;
      }

      .footer {
        display: none;
      }
    }
  }
}
