.avatar {
  @include aspect-ratio(1, 1);
  z-index: 1;
  width: 100%;

  > div {

    img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      background-color: var(--gray-lighter);

      &[src*="images?type=partner"] {
        object-position: 50% 0;
      }
    }
  }

  .lines {
    @include absolute($top: 0, $left: 0);
    z-index: -1;
    height: 100%;
    width: 100%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    overflow: hidden;

    > div {
      @include absolute($top: 0, $left: 0);
      height: 100%;
      width: 100%;
      clip-path: polygon(50% 50%, 0 0, 100% 100%);
      background-color: var(--alternative-3);
      transition: all .5s linear;
    }
  }

  &.active,
  &:hover {
    .lines {
      > :first-child {
        clip-path: polygon(100% 0, 0 0, 100% 100%);
      }

      > :last-child {
        clip-path: polygon(0 100%, 0 0, 100% 100%);
      }
    }
  }
}
