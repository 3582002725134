.layout-cover {

  &:not(.selected) .background > div {
    animation-play-state: paused;
  }

  .title-container {
    @include desktop {
      &.first {
        margin-left: #{$sidebar-width * -1};
      }
    }

    h2 {
      text-align: center;
      color: var(--title-text-color);
    }
  }

  .scroll-anim {
    @include absolute($bottom: 3rem, $left: 50%);
    transform: translateX(-50%);
    width: 28px;
    fill: none;

    @include desktop {
      margin-left: #{$sidebar-width * -.5};
    }

    @include tablet-lg {
      bottom: 4.5rem;
    }

    > :nth-child(1),
    > :nth-child(3) {
      stroke: var(--primary-strong);
    }

    > :nth-child(2) {
      fill: var(--primary-strong);
    }

    > :nth-child(3) {
      animation: anim-scroll 2.5s linear alternate infinite;
    }
  }
}

@include keyframes(anim-scroll) {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(.5rem);
  }
}
