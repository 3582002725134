.text-area-contain {
  width: 100%;
  position: relative;
  margin-top: 1rem;

  > textarea {
    height: 40px;
    padding: 1.25rem;
    border: 1px solid var(--gray-light);
    border-radius: 4px;

    &:focus {
      border-color: var(--primary);
    }

    &:focus + .label {
      .text {
        color: var(--gray-darkset);
      }
    }
  }

  .label {
    @include absolute($top: -2px, $left: 10px);
    display: flex;
    align-items: center;
    pointer-events: none;
    border: 3px solid transparent;
    background-color: transparent;

    .text {
      position: relative;
      transform: translateY(-50%);
      color: var(--gray-dark);
      padding: 0 .5rem;
      border-radius: 10%;
      background-color: transparent;
      transition: transform .15s ease-out, font-size .15s ease-out, background-color .2s ease-out,
        color .15s ease-out;

      &::after {
        content: '';
        position: absolute;
        top: 43%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--white);
        z-index: -1;
      }
    }
  }
}
