.modal-deck-information {
  height: calc(var(--vh) * 60);

  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    gap: 2.5rem;
    // padding: 1.25rem 0;

    > div {
      width: 100%;
    }

    > .label {
      width: 16%;
      // display: flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--gray-dark);
      margin-top: 2%;

      > svg {
        margin-right: .5rem;
        width: 15px;
        height: 15px;
        color: var(--gray-dark);
        margin-left: 20px;
      }
    }

    > .label-new {
      width: 16%;
      // display: flex;
      justify-content: flex-end;
      align-items: center;
      color: var(--gray-dark);
      margin: 1.25rem 0;
    }

    > .value {
      flex: 1;
      display: flex;
      align-items: center;

      .template-input {
        border: 1px solid var(--gray-light);
        border-radius: 4px;
        min-height: 42px;
        box-shadow: none;
        width: 100%;
        padding-left: 1.1rem;
      }
    }
  }
  .theme-info{
    width: 52%;


    > div {
      width: 100%;
    }

    .row
    {
      display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    gap: 0.5rem;
    
    
     .theme-label {
      width: 49%;
      justify-content: center;
      align-items: center;
      color: var(--gray-dark);
      display: inline-flex;
     
     }
}
  
}
  

  


  .add-slide {
    @include sticky($top: 0);
    display: flex;
    align-items: center;
    // padding: .75rem;
    position: unset;
    gap: inherit;
    margin-left: 20%;
    span {
      color: var(--primary);
    }

    svg {
      color: var(--primary);
      cursor: pointer;
      width: 28px;
      height: 28px;
    }
  }
  .addPartner {
    border: 1px solid grey !important;
    border-radius: 5px !important;
    height: 25px !important;
  }
  .close-btn {
    padding-top: 28px !important;
    color: #4240c2;
    margin-left: 20px;
  }
  .btn-add{
// margin-top: 10px;
margin-bottom: 20px;
  }
  .btn-title{
    color: #4240c2;
    // margin-top: 20px;
    svg {
      color: var(--primary);
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
  svg {
    color: var(--primary);
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .text {
    position: relative;
    transform: translateY(-50%);
    padding: 0 0.5rem;
    color: var(--gray-dark);
  }
  .gap-unset{
    gap: unset !important;
  }

  // .add-data{
  //   margin-top: 20px;
  // }


.text-area-contain {
  // width: 100%;
  position: relative;
  margin-top: 1rem;

  > input {
    width: 178px;
    height: 40px;
    padding: 1.35rem;
    border: 1px solid var(--gray-light);
    border-radius: 4px;
    padding-left: 10px;

    &:focus {
      border-color: var(--primary);
    }

    &:focus + .label {
      .text {
        color: var(--gray-darkset);
      }
    }
  }
  

  label {
    @include absolute($top: -10px, $left: 10px);
    display: flex;
    align-items: center;
    pointer-events: none;
    border: 3px solid transparent;
    background-color: transparent;

    .text {
      position: relative;
      transform: translateY(-50%);
      color: var(--gray-dark);
      padding: 0 .5rem;
      border-radius: 10%;
      background-color: transparent;
      transition: transform .15s ease-out, font-size .15s ease-out, background-color .2s ease-out,
        color .15s ease-out;

      &::after {
        content: '';
        position: absolute;
        top: 43%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--white);
        z-index: -1;
      }
      
    }
    
  }

  > select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  select::-ms-expand {
    display: none;
  }
  .select-client{
    color: inherit;
    background: 0px center;
    opacity: 1;
    width: 100%;
    font: inherit;
    min-width: 2px;
    margin: 0px;
    outline: 0px;
    padding: 0px;
    border: 1px solid var(--gray-light);
    border-radius: 4px;
    min-height: 42px;
    box-shadow: none;
    background-image: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
    // margin-top: 12%;
    background-repeat: no-repeat;
    background-position: right center;
    background-position-x: 91%;
    padding-left: 10px;
  }
  .select-container {
    .css-1okebmr-indicatorSeparator{
      background-color: white;
    }

  }
  
 
}
.search-info-form {
  display: grid;
  width: 100%;
  //width: 50%;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;

  > :last-child {
    grid-column: span 2;
  }
}
.theme-template {
  display: grid;
  width: 50%;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  margin-left: 20%;
  > :last-child {
    grid-column: span 2;
  }
}
}