.input-source-dropdown {
  margin: 1rem 0 ;

  .input {
    border: 1px solid var(--gray-light);
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
    height: 65px;
    margin: 0 -1.25rem;

    .actions {
      display: flex;
      gap: .5rem;
      align-items: center;
      color: var(--gray-dark);
      cursor: pointer;

      &:first-child {
        svg {
          path {
            fill: var(--gray-dark);
          }
        }
      }

      svg {
        width: 22px;
      }
    }

    input {
      margin: 0 1rem;
    }
    
    .autocomplete_wrapper .select-container .autocomplete__control {
      // border: 1px solid green;
      width: 205px !important;
    }
  }
}