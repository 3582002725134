.loading-logo {
  @include absolute($top: 50%, $left: 50%);
  transform: translate(-50%, -50%);
  width: 3rem;
  fill: none;

  > path {
    stroke-width: 3.66355;
    stroke: var(--headeer-bar-color);
    transform-origin: center;
    animation-duration: 3s;
    animation-delay: 0s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    animation-iteration-count: infinite;

    &:nth-child(1) { animation-name: anim-loading-logo-1; }
    &:nth-child(2) { animation-name: anim-loading-logo-2; }
    &:nth-child(3) { animation-name: anim-loading-logo-3; }
    &:nth-child(4) { animation-name: anim-loading-logo-4; }
  }
}

@include keyframes(anim-loading-logo-1) {
  0% { opacity: 1; }
  25% { opacity: .1; }
  50% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 1; }
}

@include keyframes(anim-loading-logo-2) {
  0% { opacity: 1; }
  25% { opacity: 1; }
  50% { opacity: .1; }
  75% { opacity: 1; }
  100% { opacity: 1; }
}

@include keyframes(anim-loading-logo-3) {
  0% { opacity: 1; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  75% { opacity: .1; }
  100% { opacity: 1; }
}

@include keyframes(anim-loading-logo-4) {
  0% { opacity: 1; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: .1; }
}
