.input-container{
  margin-top: 1rem;
  margin-left: 1.5rem;

  .label-class {
    margin-left: 10px;
  }

  .radio-input {
    margin-top: 1rem;
  }
  .template-input-container {
    margin-left: 1.5rem;


    .template-radio-input {
      margin-top: 1rem;
    }
    .font-weight-th {
      font-weight: 700;
    }
    .input-th {
      width: 5%;
    }
    .template-name {
      width: 40%;
      text-align: left;
      padding-right: 1rem;
    }
    .country {
      width: 30%;
      text-align: left;
      padding-right: 1rem;
    }
    .region {
      width: 25%;
      text-align: left;
      padding-right: 1rem;
    }
  }
  
}