$transition: all .25s linear;

.role-item {
  display: flex;
  align-items: center;
  gap: .75rem;
  padding: .5rem;
  border-radius: .25rem;
  transition: $transition;

  .drag {
    svg {
      path {
        fill: var(--gray-mid);
      }
    }
  }

  .name {
    flex: 1;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 1rem;

    .action {
      opacity: 0;
      visibility: hidden;
      transition: $transition;

      svg {
        height: 1rem;
        width: 1rem;
      }

      &.selected {
        opacity: 1;
        visibility: visible;

        svg {
          color: var(--primary);
          transition: $transition;
        }
      }
    }
  }

  &:hover {
    background-color: var(--gray-lighter);

    .actions {
      .action {
        opacity: 1;
        visibility: visible;

        svg {
          color: var(--primary);
        }

        &.selected {
          opacity: 1;
          visibility: visible;
  
          svg {
            height: 1.2rem;
            width: 1.2rem;
            color: var(--primary);
            transition: $transition;
          }
        }
      }
    }
  }
}
