@use 'sass:meta';

$controls-height: 3.75rem;

.slide-preview {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 1.5rem 1.5rem;

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 -1.5rem .75rem;
    min-height: $controls-height;
    padding: 0 1rem;

    > button {
      margin-right: .25rem;
    }
  }

  .preview {
    display: flex;
    height: 100%;
    overflow: hidden;

    > div {
      @include aspect-ratio(16, 9);
      width: 100%;
      margin: auto;

      &.mobile {
        @include aspect-ratio(414, 736);
      }
    }
  }
}

#root-preview {
  @include absolute($top: 0, $left: 0);
  z-index: 1;
  width: 1040px;
  height: 585px;
  transform-origin: top left;
  border: 1.5px solid var(--gray-light);
  background-color: var(--white);
  overflow: hidden;

  &.mobile {
    width: 414px;
    height: 736px;
  }

  @import '../../../clientSide/styles/_variables';
  @import '../../../clientSide/styles/_mixins';
  @import '../../../clientSide/styles/_typos';
  @import '../../../clientSide/styles/_brands';
  @import '../../../clientSide/styles/_general';
  @include meta.load-css('../../../../src/clientSide/styles/_reset');

  @import '../../../clientSide/components/layouts/_styles';
  @import '../../../clientSide/components/shared/_styles';

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-top: 2.25rem !important;

    @include tablet-lg {
      padding-top: 1rem !important;
    }
  }
}
