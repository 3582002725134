*,
*::before,
*::after {
  font-family: var(--font);
  font-weight: 500;
  line-height: 120%;
}

[class^="brand-"] {
  color: var(--body-text-color);
}

[class^="layout-"] {
  padding: 5.5rem 2.25rem 2.25rem;

  @include tablet-lg {
    padding: 4rem 2rem 2rem;
  }

  > .wrapper,
  > .flex-wrapper {
    max-width: $max-screen-width;
    width: 100%;
    height: 100%;
  }

  > .flex-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
}

.input-wrapper {
  margin: 1.25rem 0;

  label {
    @extend .body;
  }

  input,
  textarea {
    @extend .body-2;
    width: 100%;
    border: 1px solid var(--gray-light);
    border-radius: 3px;
  }

  input {
    padding: 0 1rem;
    height: 34px;
  }

  textarea {
    min-height: 5rem;
    padding: 1rem;
    resize: vertical;
  }
}
